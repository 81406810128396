import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from 'axios';
import { VStepperWindowItem } from 'vuetify/lib/components/index.mjs';

axios.defaults.baseURL='https://e-pdrrmo.online/backend/'

loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .mount('#app')

  